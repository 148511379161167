@import 'variables/colors';

$form-border-color: $text-grayscale-light;

.form__select, .form__text-input{
    padding: 10px;
    margin-bottom: .5rem;
}

.styled-select, .form__text-input{
    border: 1px solid $form-border-color;
}