
.footer__recaptcha{
	margin-top: .5rem;
}

.footer__signup-header{
	font-weight: 100;
}

.footer__signup .form__message{
	color: #000;
}

.register-footer__input-container{
	flex-grow: 1; 
	margin-right: 1rem;
}

.register-footer__input-container .footer__signup-input{
	width: 100%;
}
