
@import "scss/variables/typography";

.toggle-link{
	float: right;
	color: #fff;
	padding-right: 1em;
	font-size: 1.5em;
	margin-top: -.25em;
}
.nav__link{
	color: #fff;
}

.subnav__link a{
	color: #444;
	font-weight: normal;
}

.toggle-link:hover, .nav__link:hover{
	cursor: pointer;
}

.toggle-link__img{
	height: .625rem;
	width: .75rem;
}

.toggle-link__img--expanded{
	transform: scale(1, -1);
}

.subnav{
	padding-left: .5rem;
	margin-left: 0px;
}
