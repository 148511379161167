
@import "scss/variables/typography";
@import "scss/variables/breakpoints";

	.hp-bookforum__web{
		margin-top: 3rem;
	}

	.hp-bookforum__section-heading{
		margin: -.25em 0px .5em 0px;
		font-weight: $book;
		font-size: 1.5rem;
		color: red;
	}

	.hp-bookforum__web-article,
	.hp-bookforum__print-article{
		margin-bottom: 1rem;
	}

	.hp-bookforum__title--web{
		margin: 0;
	}

	.hp-bookforum__teaser--web,
	.hp-bookforum__web-category,
	.hp-bookforum__web-title{
		font-size: 1.25rem;
	}

