.focus--mouse:focus {
	outline: none;
}

.ot-sdk-show-settings:after {
    background-image: url(~img/onetrust.svg);
    background-repeat:no-repeat;
    background-size:contain;
    content:"";
    display:inline-block;
    height:.875rem;
    margin-left:.4rem;
    position:relative;
    top:-1px;
    vertical-align:middle;
    width:1.875rem
}