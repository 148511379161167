
@import "scss/variables/typography";

.related-articles__slides {
    margin:1rem 0 1rem 0;
    display: block;
}

.related-articles__slide {
    text-align: center;
    width: calc(33.3333333% - 1.33333333333rem);
    // padding: 0 1.5% 0 1.5%;
    padding-right: 2rem;
    &:last-child{
    	padding-right: 0px;
    }
}

.related-articles__slider .prev{
    position: absolute;
    top: 31% !important;
}

.related-articles__slider .next{
    position: absolute;
    top: 31% !important;
}

.related-articles__slider .prev, .related-articles__slider .next{
    display: none;
}

@media screen and (min-width: 840px){
    .related-articles__slider .prev, .related-articles__slider .next{
        display: inline-block;
    }
}
