
@import "scss/variables/breakpoints";

    .gallery-ads--mobile{
        display: flex;
        justify-content: space-between;
        position: relative;
        //top: -4rem;
        margin-bottom: 100px;
    }
    .gallery-ad--mobile{
        width: 23%;
        height: 0px;
        padding-bottom: 23%;
        max-width: 80px;
    }

    .gallery-ad--mobile img{
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: $breakpoint-medium){
        .gallery-ads--mobile{
            margin-bottom: 60px;
        }
    }

    @media screen and (min-width: $breakpoint-medium){
        .gallery-ads--mobile, .gallery-ad--mobile * {
            display: none;
        }
    }
