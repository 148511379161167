
	@import "scss/variables/typography";
	.bold-toggle .toggle__control{
		font-weight: $medium;
		padding: 1rem 0px;
		font-size: 1.0625rem;
	}

	.toggle{
		margin-bottom: 1rem;
	}

	.toggle.toggle--slim{
		margin-bottom: 0;
		.toggle__content{
			padding: 0px !important;
		}
		.toggle__control{
			padding: 0;
		}
	}

	.bold-toggle .toggle__control{
		font-weight: $medium;
		padding: 1rem 0px;
		font-size: 1.0625rem;
	}

	.toggle__control{
		display: block;
		margin: 0px 0px .5rem 0px;
		background-size: 1rem 1rem;
		background-position: left; 
		background-repeat: no-repeat;
	}

	.toggle__content{
		padding: 1rem;
    	background: #fafafa;

    	.styled-select, .form__text-input{
    		background-color: #fff;
    	}
	}
