
@import "scss/variables/breakpoints";
@media screen and (max-width: $breakpoint-medium){
	.search__toggle--hidden{
		display: none;
	}

	.search__icon{
		bottom: -2px;
	}
}

@media screen and (min-width: $breakpoint-medium){
	.search__icon{
		top: -2px;
	}
}
