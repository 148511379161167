.carousel__slides{
    max-width: 700px;
}

li.carousel__slide {
    position: relative;
    display: inline-block;
    text-align: center;
    /**
     * (optional) if the content inside the slide element has a defined size.
     */
    width: 100%;
    max-width: 700px;
}

.carousel__img{
    display: block;
    width: 100%;
}

.carousel__caption{
    max-width: 100%;
    height: auto;
    overflow-x: hidden;
    white-space: normal;
    line-height: 1.5em;
    font-size: 1rem;
    font-family: $sans-base-font-family;
}

.carousel__caption--small{
    font-size: .75rem;
}

.carousel__caption--issue-title{
    font-size: 1.125rem;
    text-align: left;
    width: 90%;
}

.carousel__caption p{
    max-width: 100%;
}

.carousel__placeholder-caption{
    line-height: 0px;
    p{
        margin: 0px;
    }
}

.js_dots{
    text-align: center;
}

.js_dots li{
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #aaa;
    margin-right: 15px;
    border-radius: 8px;
}

.js_dots li.active{
    background: #000;
}

@media screen and (max-width: 840px){
    .carousel__slides{
        width: 100vw;
    }
    li.carousel__slide{
        width: 100vw !important;
        margin: 0;
        padding: 0;
    }

    li.carousel__slide img{
        display: block;
        padding: 0px;
    }

    .carousel__caption{
        padding: 0px 1rem;
    }
}

@media screen and (min-width: 840px){
    li.carousel__slide{
        max-width: auto;
        // width: 700px;
    }

    .slider .prev, .slider .next{
        display: inline;
    }
}

span.next:hover, span.prev:hover{
    cursor: pointer;
}