
@import "scss/variables/breakpoints";

.popup-ad{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 50vw;
    max-width: 600px;
    max-height: 600px;
}
@media (max-width: $breakpoint-medium){
    .popup-ad{
        width: 90vw;
        height: 90vw;
    }
}
