
@import "scss/variables/typography";
.share{
	text-align: right;
	height: 20px;
}

.share__open{
	display: block;
	font-family: $sans-base-font-family;
	font-size: .875rem;
	font-weight: $demi;
	line-height: 20px;
	text-transform: uppercase;
}

.share__open img{
	width: 2rem;
	margin-top: -.25rem;
}

.share__open, .share__collapse{
	color: #000;
}

.share img{
	max-height: 100%;
	max-width: 100%;
}
