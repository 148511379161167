
@import "scss/variables/breakpoints";
.mobile-search{
	display: none;
}

@media screen and (max-width: $breakpoint-medium){
	.mobile-search{
		display: inline-block;
		flex-grow: 1;
		text-align: right;
	}

	.mobile-search__area{
		width: 90%;
		position: fixed;
		top: 20%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-bottom: 1px solid #000;
		text-align: left;
	}

	.search__input{
		width: 100%;
		background: transparent;
	}

	.search__submit{
		visibility: hidden;
	}

	.search__suggestions{
		top: 1rem;
	}
}
