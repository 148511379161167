@media print {

    @page{
        size: 8.5in 11in;
        margin: .75in;
    }

    img{
        max-height: 5in !important;
        width: auto !important;
    }

    .top-navigation__logo svg{
        max-width: 2in !important;
    }

    .viewport-container {
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: 0px;
    }

    .header{
        position: static !important;

        .header__top {
            justify-content: space-between;
            align-items: flex-end;
            text-align: baseline;
        }
    }

    .search__module{
        flex-grow: 0 !important;
    }

    .app--artguide .header__top::after{
        content: 'artguide';
        font-size: 2rem;
        line-height: 1em;
        vertical-align: bottom;
        font-weight: $medium;
        display: inline;
    }

    .footer {
        background: none;
        margin-top: 0;
        padding: 0;

        .footer__signup,
        .footer__site-links {
            display: none;
        }

        .footer__links {
            color: #000;
            padding-left: 0;
        }
    }

    .top-navigation__logo{
        position: static;
    }

    .slug{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .print-article__issue-title{
        font-size: 1.25rem !important;
        margin-top: 0px;
    }

    .blog-article__image--landscape, .blog-article__image--landscape img{
        width: auto !important;
    }

    .blog-article__caption{
        display: block;
    }

    .ads,
    .gallery-ads,
    .lightbox,
    .related-articles,
    .share,
    .footer__signup,
    .footer__site-links,
    .footer__copyright a, 
    .search__module,
    .search__icon,
    .sf-toolbar,
    .sf-minitoolbar,
    .search,
    .top-navigation,
    .cart-widget,
    .guide-header__map,
    .guide-header__places,
    .guide-header__explore-button,
    .artguide-nav,
    .print-article__issue-cover,
    .Ad
    {
        display: none !important;
    }

    .video-wrapper{
        height: 1rem !important;
        padding-bottom: 0px !important;
    }

    .video-wrapper iframe{
        display: none !important;
    }

    .video-wrapper::after{
        content: '[video]';
    }

    .review__place{
        margin-top: 1rem;
    }

    .footer{
        background: none;
        position: static;
    }

    // artguide
    .artguide-wrapper {
        padding-bottom: 0;
    }

    .guide-header__content {
        height: 40px !important;
    }

    .guide-header__places{
        display: none;
    }

    .guide-header__content{
        height: auto !important;
        text-align: left !important;
    }

    .guide-header__title {
        padding: 0px !important;
        font-size: 1.5rem !important;
    }

    .guide-listing{
        font-size: .5rem !important;
        margin: 1rem !important;
    }

    .guide-listing__location,
    .guide-listing__location h2,
    .guide-listing__exhibition__description{
        margin: 0px !important;
    }

    .artguide-listings__show-all{
        display: none;
    }

    .artguide-listings__section{
        margin-bottom: 0px !important;
    }

    .artguide-listings__section__subtitle,
    .artguide-listings__section__title{
        font-size: 1rem !important;
    }

    .artguide-listings__section__title{
        margin: 0px;
    }

    .artguide-listings__section__title:after{
        //display: none;
    }

    .guide-listing {
    .guide-listing__info__content {
      height: auto;
      min-height: 0;
    }

    .guide-listing__location-data a,
    .guide-listing__location-data__address--map-mounted {
      text-decoration: none;
    }

    & .guide-listing__options,
    & .guide-listing__image,
    & .guide-listing__exhibition__more,
    & .guide-listing__share-panel {
      display: none;
    }
  }

    .guide-venue {
    .guide-venue__info__content {
      min-height: 0;
    }

    .guide-venue__location__data__address--map-mounted,
    .guide-venue__location__data a {
      text-decoration: none;
    }

    & .guide-venue__image,
    & .guide-venue__options {
      display: none;
    }
  }
}
