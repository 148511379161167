
@import "scss/variables/breakpoints";
    .gallery-ads{
        text-align: center;
        border-left: 1px solid #dfdfdf;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 160px;
        margin-top: 0px;
        flex-shrink: 0;
    }

    .gallery-ad--desktop{
        width: 110px;
        margin-bottom: 50px;
    }

    @media screen and (max-width: $breakpoint-large){
        .gallery-ads {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    @media screen and (max-width: $breakpoint-medium + 16px){
        .gallery-ads,  .gallery-ad * {
            display: none;
        }
    }
