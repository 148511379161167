
.related-articles__container{
	display: flex;
	margin: 1rem 0;
}
.related-article--static{
	flex-basis: 0;
	flex-grow: 1;
	margin: 0 1.5%;
	max-width: 48%;
}

.related-article--static{
	margin: 0 1.5%;
}

.related-article--static:first-child{
	margin: 0 1.5% 0 0 !important;
}

.related-article--static:last-child{
	margin: 0 0 0 1.5%;
}
