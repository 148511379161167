
@import "scss/variables/breakpoints";
@import "scss/variables/grid";
@import "scss/variables/typography";

.search__module{
	position: relative;
	font-size: 1.25rem;
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.search__controls{
	flex-grow: 1;
}

@media screen and (max-width: $breakpoint-medium){
	.search__module{
		display: none;
	}
}


