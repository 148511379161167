.search__input{
    padding: 0;
    border: 0;
    font-weight: 100;
    text-transform: uppercase;
    text-align: right;
    width: 100%;
}

.search__input::placeholder{
    color: $text-grayscale-dark;
}

.search__input::-webkit-input-placeholder{
    color: $text-grayscale-dark;
}

.search__submit{
    background: none;
    color: $text-grayscale-dark;
    font-style: normal;
    padding-right: 0;
    visibility: hidden;
    position: absolute;
}

.search__input.visible{
    display: block;
}

.search__suggestions{
    margin-left: 0;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 1.45em;
    text-align: right;
    background: #fff;
    z-index: 3;
}

.advanced-search__buttons{
    justify-content: flex-start !important;
}

@media screen and (min-width: $breakpoint-medium){
    .search__module{
        padding-left: 0px;
    }
    .search__toggle{
        padding: 0px -.25rem 0px .5rem;
    }
}

@media screen and (min-width: $breakpoint-large){
    .search__toggle{
        right: 0px;
    }
}