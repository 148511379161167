
@import "scss/variables/issue";

.nav__item{
	display: block;
	position: relative;
	overflow: visible;
}

.nav__item:hover{
	cursor: pointer;
}

.subnav__item a:hover, .nav__item--clickable a:hover{
	color: $issue-color;
}

.nav__item--current > a{
	color: $issue-color;
}

.toggle-link:hover{
	cursor: pointer;
}

.subnav{
	position: absolute;
	left: -.5em;
	padding: .25em .25em .5em .5em;
	background: #fff;
	padding-top: 1.5rem;
	margin-left: 0px;
	text-align: left;
	width: 175px;
}

.subnav__item{
	display: block;
	overflow-x: visible;
	margin-bottom: .25em;
}

.bigbold{
	line-height: .625em;
}
