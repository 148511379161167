@import 'variables/colors';

input{
    border-radius: 0;
    position: relative;
}

.required::after, span[data-required-field="true"]::after{
    content: ' *';
    font-weight: bold;
}

.form{
    font-family: $sans-base-font-family;
}

.form h2{
    text-transform: uppercase;
}

.order-form{
    flex-basis: 50%;
}

.discount-form{
    width: 40%;
}

.form__header{
    font-size: 1.5625rem;
    font-weight: normal;
}

.form__text{
    font-size: 1.125rem;
}

.form__select, .form__text-input{
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.form__text-input{
    -webkit-appearance: none;
}

.form__text-input--error{
    border: 1px solid red !important;
}

.form__select, .form__text-input--no-border{
    border: none !important;
}

.form__text-input--inline{
    display: inline-block;
}

.form__text-input--quantity{
    width: 3rem !important;
    text-align: center;
}

.form__text-input::placeholder{
    text-transform: uppercase;
    font-size: 1rem;
    color: #666;
}

.form__checkbox-row{
    display: flex;
    justify-content: flex-start;
}

.form__checkbox{
    appearance: none;
    visibility: hidden;
    height: 0px;
    width: 0px;
}

.form__checkbox + label{
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 1px solid #000;
    cursor: pointer;
    margin: .125rem .5rem 0px 0px;
    box-sizing: content-box;
}

.form__checkbox:checked + label span{
    display: block;
    margin: .125rem;
    background: #000;
    height: .75rem;
}

.form__submit{
    padding: 18px 25px;
    background: #fff;
    //border: 1px solid #000;
    border: 1px solid $text-grayscale-light;
    border-radius: 0px;
    font-size: 1.125rem;
    font-weight: $demi;
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin-top: 1rem;
}

.form__submit--inverted{
    padding: 18px 25px;
    background: #000;
    color: #fff;
    font-weight: $demi;
    font-size: 1.125rem;
}

.form__submit--centered{
    margin: 0 auto;
}

.form__fine-print{
    font-size: .875rem;
    line-height: 1rem;
}

.form__section-padding {
    padding: 15px 0 30px;
}

.form__message{

}

.form__message--success{
    
}

.form__message--error{
    color: red;
}

.form__message--emphasized{
    font-weight: $demi;
}

.form__message--error a{
    color: red;
    border-bottom: 1px solid red;
}

.errorMessage{
    color: $error-text-color;
}

.form__field-error{
    font-size: .625rem;
    line-height: 1em;
    color: $error-text-color;
}

.form__field-error--white{
    color: #fff !important;
}

.styled-select{
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    background: url(../img/arrow_black_down.svg) no-repeat;
    background-position: right 1rem center;
    background-size: .75rem;
    border: 1px solid $text-grayscale-light;
    border-radius: 0px;
    overflow: hidden;
    //width: 100%;
    padding: .64rem;
    min-width: 200px;
    margin-bottom: .5rem;
}

.styled-select--full-width{
    width: 100%;
}

.styled-select--slim{
    padding: .25rem;
}

.styled-select--disabled{
    opacity: .5;
}

.styled-select select{
    -webkit-appearance: none;
     -moz-appearance: none;
      appearance: none;
    background: transparent;
    width: 120%;
    border: none;
    height: 1.5rem;
}

.checkbox-container{
    line-height: 0px;
}

.checkbox-item{
    margin-bottom: .5rem;
}

#registerPageRecaptcha{
    margin-top: 1rem;
}

select.focus--mouse:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}


// Styles pertaining to vee-validate automatically generated classes

.touched.invalid{
    border: 1px solid $error-border;
}

.form__text-input.touched.invalid:focus{
    border: 1px solid #666;
}

.footer__signup-input.touched.invalid:focus{
    border: none;
}

.flex input[type="text"], .flex .styled-select, .flex .el-date-editor, .flex .form__submit{
    margin-right: 1rem;
}

// using a direct descendent rule here because twig automatically wraps submit buttons in a div
.flex > input[type="text"], .flex > .styled-select, .flex > .el-date-editor, .flex > .form__submit{
    &:last-child{
        margin-right: 0;
    }
}

@media screen and (min-width: $breakpoint-medium){
    .desktop-flex input[type="text"], .desktop-flex .styled-select, .desktop-flex .el-date-editor, .desktop-flex .form__submit{
        margin-right: 1rem;
    }

    // using a direct descendent rule here because twig automatically wraps submit buttons in a div
    .desktop-flex > input[type="text"], .desktop-flex > .styled-select, .desktop-flex > .el-date-editor, .desktop-flex > .form__submit{
        &:last-child{
            margin-right: 0;
        }
    }
}

@media screen and (min-width: $breakpoint-medium){
    .form{
        max-width: 510px;
    }
}

@media screen and (max-width: $breakpoint-medium){
    .styled-select{
        display: block;
    }
}