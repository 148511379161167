
.dotdotdot{
	min-width: 56px;
}
.dot{
	width: 12px;
	height: 12px;
	display: inline-block;
	margin-right: 8px;
	animation-name: pulse;
	animation-duration: 800ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-direction: alternate;
}

.dot--inverted{
	animation-name: pulseInverted !important;
}

.dot1{
	animation-delay: 0ms;
}

.dot2{
	animation-delay: 200ms;
}

.dot3{
	animation-delay: 400ms;
}

@keyframes pulse {
	from {
		background: rgba(0, 0, 0, 1);
	}
	to {
		background: rgba(0, 0, 0, .25);
	}
}

@keyframes pulseInverted {
	from {
		background: rgba(255, 255, 255, 1);
	}
	to {
		background: rgba(255, 255, 255, .25);
	}
}
