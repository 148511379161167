.anchor-link, .anchor{
	display: block;
	position: relative;
	top: -122px;
	visibility: hidden;
	line-height: 0px;
}

.noscroll{
	position: relative;
	overflow: hidden;
	height: 100vh;
}

/* 
 *  this rule can be removed some after Apple fixes this bug
 *  https://bugs.webkit.org/show_bug.cgi?id=176896
 */
.iosFormFix{
	position: fixed !important;
	overflow: hidden;
	height: 100vh;
}

.border{
	margin: 0;
	border: none;
    height: 1px;
}

.overlay{
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(255, 255, 255, .95);
	display: table;
	transition: opacity .25s ease;
}

.overlay--dark{
	background-color: rgba(0, 0, 0, .7) !important;
}

.overlay__wrapper{
	overflow: scroll;
	max-height: 100vh;
	padding-bottom: 3em;
}

.overlay__header{
		position: fixed;
		padding: 5%;
		width: 90%;
		transition: transform .5s ease;
		top: 0;
}

.overlay__content{
	max-height: 100vh;
	margin: 0 auto;
	text-align: left;
	margin-top: 60px;
	// position: relative;
}

.overlay__collapse{
	position: static;
	width: 1.5rem;
	height: 1.5rem;
	//background: none;
	float: right;
	background: url(../img/x-01.svg);
	padding: 0px;
	cursor: pointer;
}

.overlay--dark .overlay__collapse{
	background: none;
	&::before{
		position: absolute;
		display: block;
		width: 2rem;
		border-top: 2px solid #fff;
		transform: rotate(45deg);
		content: '';
	}
	&::after{
		position: absolute;
		display: block;
		width: 2rem;
		border-bottom: 2px solid #fff;
		transform: rotate(315deg);
		content: '';
	}
}

.toggle-button{
	background: none;
}

.article-content-width{
	max-width: $article-content-width;
}

.play-arrow{
	position: absolute;
	bottom: 2rem;
	right: 2rem;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 20px solid white;
}

.video-caption{
	margin:0px;
	@include caption-typography;
	line-height: 1em;
}

.desktop-sticky{
	@media screen and (min-width: $breakpoint-medium){
		position: sticky;
		top: $sticky-position-top;
	}
}

.mobile-sticky{
	@media screen and (max-width: $breakpoint-medium){
		position: sticky;
		top: $sticky-position-top-mobile;
	}
}

.relative{
	position: relative;
}

.hidden{
	display: none;
}

// .paywalled{
// 	color: $text-grayscale-light;
// 	a{
// 		color: $text-grayscale-light;
// 	}
// }

.grey-bg{
	background: #eee;
	padding: 1.5rem;
}

.weight-medium{
	font-weight: $medium;
}

.align-right{
	text-align: right;
}

.book{
	font-weight: $book;
}

.centered-block{
	margin-left: auto;
	margin-right: auto;
}

.truncated::after{
	content: '...'
}

.infolink::after {
    font-size: 1ex;
    line-height: 1ex;
    vertical-align: middle;
 	color: #aaa;
 	margin-left: .5rem;
 	border: 1px solid #aaa;
    border-radius: 1.8ex;
    padding: 1px 1ex 1px 1ex;
    text-decoration: none;
    content: "?";
}

.standard-text{
	@include article-body-typography;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.ul--discs, .ul--discs li{
	list-style: disc !important;
	padding-left: 1rem !important;
}

@media screen and (min-width: $breakpoint-medium){
	.overlay__collapse{
		position: absolute;
		top: 2em;
		right: 2em;
	}
	.overlay__wrapper{
		display: table-cell;
		vertical-align: middle;
	}
	.overlay__header{
		background: transparent;
	}
}