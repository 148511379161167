
@import "scss/variables/breakpoints";
@import "scss/variables/typography";
@import "scss/transitions/fade";

.lightbox__header{
	position: fixed;
	padding: 5%;
	width: 90%;
	background: #fff;
	transition: transform .5s ease;
	top: 0;
	z-index: 1;
}

.lightbox__header--hiddenMobile{
	transform: translate(0, -100%);
}

.lightbox__logo{
	height: 1.5rem;
}

.lightbox__title{
	display: inline;
	margin: 0;
	padding: 0;
	font-size: 1.125rem;
	// margin-left: 1em;
	text-transform: uppercase;
	font-weight: normal;
}

.lightbox__wrapper{
	overflow: scroll;
	// touch scrolling is breaking the scroll for some reason...
	-webkit-overflow-scrolling: touch;
	height: 100vh;
	padding-bottom: 3em;
}

.lightbox__content{
	max-width: 800px;
	margin: 0 auto;
	text-align: left;
	margin-top: 60px;
}

.lightbox__content--visible{
}

.lightbox__image{
	width: 100%;
	object-fit: contain;
	max-height: 90vh;
}

.lightbox__caption{
	color: #000;
	display: inline-block;
	text-align: left;
	font-size: .875rem;
	line-height: 1rem;
	padding: 0px 5%;
	margin-top: .75rem;
}

.lightbox__navigation{
	text-align: right;
	display: none;
}

.lightbox__nav-link{
	margin: 0 .5rem;
}

.lightbox__nav-link--disabled{
	opacity: .5;
}

.lightbox__nav-image{
	height: 1rem;
}

.lightbox__count{
	text-align: right;
	padding-right: 5%;
}

@media screen and (max-width: $breakpoint-medium){

	.lightbox{
		-webkit-overflow-scrolling: touch;
		background: #fff;
	}

	.lightbox__content{
		margin-top: 30px;
	}

	.lightbox__content:first-of-type{
		margin-top: 92px;
	}

	.lightbox__content:last-of-type{
		padding-bottom: 3em;
	}

	.lightbox__collapse{
		float: right;
	}
}

@media screen and (min-width: $breakpoint-medium){
	.lightbox{
		display: table;
	}

	.lightbox__wrapper{
		display: table-cell;
		vertical-align: middle;
	}

	.lightbox__logo{
		display: none;
	}

	.lightbox__title{
		display: none;
	}

	.lightbox__collapse{
		position: absolute;
		top: 2em;
		right: 2em;
	}
	.lightbox__header{
		background: transparent;
	}

	.lightbox__content{
		display: none;
		max-height: 90vh;
	}
	.lightbox__content--visible{
		display: table;
	}
	.lightbox__image{
		width: auto;
		max-width: 100%;
		max-height: 100%;
		display: block;
		margin: 0 auto;
		max-height: 80vh;
	}
	.lightbox__caption{
		padding: 0px;
		display: table-caption;
		caption-side: bottom;
		min-height: 4em;
	}
	.lightbox__navigation{
		display: block;
		position: absolute;
		bottom: 5rem;
		right: 3em;
		font-size: 1.25rem;
	}
	.lightbox__count{
		display: none;
	}
}
