$max-content-width: 1280px;

$mobile-margins-left: 5vw;
$mobile-margins-right: 5vw;

$medium-margins-left: 3rem;
$medium-margins-right: 3rem;

$main-column-width: 1064px;
$article-listing-content-width: 750px;
$article-content-width: 700px;

$header-height-mobile: 62px;
$header-height-desktop: 132px;

$footer-height-mobile: 384px;
$footer-height-desktop: 208px;

$sticky-position-top: $header-height-desktop;
$sticky-position-top-mobile: $header-height-mobile + 1;

$artguide-wide-width: 1280px;
$artguide-medium-width: 960px;
$artguide-narrow-width: 800px;
$artguide-min-width: 600px;

$listing-image-width: 810px;

$footer-padding: 60px;

$slug-margin-val: 3rem;
$slug-margin-val-mobile: 1.5rem;

$slug-margin: $slug-margin-val 0 $slug-margin-val 0;
$slug-margin-mobile: $slug-margin-val-mobile 0 $slug-margin-val-mobile 0;
