
	
	@import "scss/variables/breakpoints";

	.hp-news__slider .next, .hp-news__slider .prev {
		top: 32%;
	}

	.hp-news__slides{
		display: block;
	}

	.hp-news__slide{
		width: calc(25% - .75rem);
		padding-right: 1rem;
	}

	

	.hp-news__more-news-link{
		position: relative;
		display: block;
		height: 0px;
		padding: 50%;
		background: #fafafa;
	}

	.hp-news__more-news-link__text{
		position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%, -50%);
    	font-weight: 100;
    	text-transform: uppercase;
    	font-size: 1.25rem;
	}

	@media screen and (min-width: $breakpoint-small){
		.hp-news__slide:nth-of-type(4n){
			padding-right: 0px;
		}
	}

	@media screen and (max-width: $breakpoint-small){
		.hp-news__frame{
			width: 80vw;
			margin: 0 auto;
			overflow: visible;
		}
	
		.hp-news__slide{
			width: 80vw;
			padding: 0 2rem 0 0;
		}
	
		.hp-news__slide:first{	
			position: relative;
		}
	}
