/* GENERAL */
.video__category{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: $book;
    margin: 1rem 0 .25rem 0;
}

.video-category-select select{
    text-transform: uppercase;
}

.video-outer-wrapper,
.video-detail__content{
    max-width: 1040px;
    margin: 2rem auto 0px auto;
}

.video-wrapper{
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-wrapper__Bilibili {
    padding-bottom: 62.5% !important; /* 16:10 */
}

.video__title--featured, .video__title--single, .video__title--category{
    line-height: 1.125em;
}

@media screen and (max-width: $breakpoint-medium){
    .video-wrapper.mobile-full-width{
        padding-bottom: 56.25vw;
    }
}

.video-wrapper iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0px;
}

/* HOMEPAGE */

/* featured videos */

.video__slider--featured{
    margin-bottom: 3rem;
}

.video__title--featured{
    font-size: 2.5rem;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 500;
    margin: .25em 0 0;
}

.video__content--featured{
    font-size: 1.125rem;
    text-transform: uppercase;
    line-height: 1.2222em;
    font-weight: $book;
    margin: 0px;
}

.video-slider__slides--featured{
    margin-top: 0px;
}

.video-slider__slide--featured{
    opacity: .5;
    &.active{
        opacity: 1;
    }
}

.video-slider__frame--featured{
    margin: 0 auto;
    overflow: visible !important;
}

.video-slider__frame--featured,
.video-slider__slide--featured{
    width: 70vw;
    max-width: $max-content-width !important;
}

// .video__title--featured{
//     font-weight: $medium;
//     font-size: 2rem;
//     margin-top: 0px;
// }

.video__title--single{
    font-weight: $medium;
    font-size: 2rem;
    line-height: 1em;
}

.video-slider__slide--featured{
    .video__title--featured,
    .video__content--featured,
    .video__category--featured{
        visibility: hidden;
    }    
}

.video-slider__slide--featured.active {
    .video__title--featured,
    .video__content--featured,
    .video__category--featured{
        visibility: visible;
    }
}

.video__slider--featured .video__next--featured,
.video__slider--featured .video__prev--featured {
    top: 20%;
}

@media screen and (min-width: $breakpoint-xl){
    .video-slider__slide--featured,
    .video-slider__frame--featured{
        //width: $max-content-width !important;
    }
}

@media screen and (max-width: $breakpoint-medium){
    .video-slider__slide--featured,
    .video-slider__frame--featured{
        width: 100vw !important;
    }

    .video-slider__frame--featured{
        width: 100vw;
        margin: 0px 0px 0px 0px;
    }

    .video-category-select{
        margin-bottom: 2rem;
    }
}

@media screen and (min-width: $breakpoint-medium){

    .video__slider--featured .video__prev--featured,
    .video__slider--featured .video__next--featured {
        top: 35%;
        height: 4rem !important;
    }

    .video__next--featured .slider__arrow,
    .video__prev--featured .slider__arrow {
        height: 4rem !important;
    }

    .video__prev--featured{
        position: absolute;
        left: 5vw !important;
        background: none !important;
    }

    .video__next--featured{
        position: absolute;
        right: 5vw !important;
        background: none !important;
    }
}

/* HOMEPAGE videos by category */

.video__slider--category{
    margin: 1rem 0px 4rem 0px;
}

.video-slider__slides--category{
    display: block;
}

.video-slider__slide--category{
    width: 100vw;
}

.video-slider__slide--category:last-child{
    margin-right: 0px;
}

.video__content--category{
    max-height: 6.125em;
    overflow: hidden;
    position: relative;
    margin-top: 1rem;
}

.video__content--category p{
    margin-top: 1.5em;
}

.video__content--category p:first-child{
    margin-top: 0px;
}

.video__title--category{
    font-size: 1.5rem;
    font-weight: $medium;
    margin: 0px;
}

.video__slider--category .video__prev--category, 
.video__slider--category .video__next--category {
    top: 23%;
}

@media screen and (min-width: $breakpoint-medium){
    .video-slider__slide--category{
        width: calc(33.3333333% - 1.33333333333rem);
        padding-right: 2rem;
        &:last-child{
            padding-right: 0px;
        }
    }
}

/* CATEGORY LISTING */

.video__tags{
    display: block;
    margin-top: 1rem;
    text-align: right;
}

.video__tag{
    padding: .5rem;
    border: 1px solid #000;
    //float: right;
    margin-left: 2rem;
    display: inline-block;
}

.video-category-listing__video{
    margin-bottom: 3rem;
}

@media screen and (min-width: $breakpoint-medium){

    .video-category-listing{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    // a bit of a hack below, to prevent gap-toothed justification
    .video-category-listing::after{
        content: "";
        flex: auto;
    }
    
    .video-category-listing__video{
        width: 30%;
        padding-right: 3.33333%;
        flex-shrink: 0;
        margin: 1rem 0px 3rem 0px;
    }

    .video-category-listing__video:nth-child(3n){
        padding-right: 0px;
    }

}

/* SPONSORED CONTENT */

.sponsored-content__video {
    background-color: #EBEBEB;
    margin-bottom: 6rem;
}

.sponsored-content__video .article-list__article {
    margin: 30px;
}

.video-listing .article-list__article-meta {
    margin-bottom: 0px;
}

.slug--sponsored-content {
    text-transform: uppercase;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1em;
    font-family: "franklin-gothic-urw", Helvetica, sans-serif;
    display: block;
    padding: 40px 0 20px 0;
}

.sponsored-content__video .article-list__h1 {
    text-transform: none;
}

@media screen and (max-width: $breakpoint-medium){
    .sponsored-content__video .article-list__primary-image {
        width: 80vw !important;
        margin-left: 0;
    }
    .sponsored-content__video {
        margin-bottom: 4rem !important;
    }
    .sponsored-content__video .article-list__article {
        margin: 20px;
    }
    .sponsored-content__video .article-list__h1 {
        font-size: 1.4rem;
    }
    .sponsored-content__video .article-list__article-meta {
        font-size: 1.2rem;
    }
}
