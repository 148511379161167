.lory__wrapper{
	overflow: hidden;
}

.lory__slider{
	position: relative;
}

.lory__frame{
	position: relative;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	white-space: nowrap;
	z-index: 0;
	width: 100%;
}

.lory__slides{
	display: inline-block;
	//margin: 1rem 0 1rem 0; TODO: ADD THIS BACK IN ELSEWHERE, WHERE NEEDED
	padding: 0;
}

.lory__slide{
	position: relative;
	display: inline-block;
	white-space: normal;
	font-size: 1rem;
	line-height: 1.25rem;
	vertical-align: top;
}

.lory__slider .prev{
	position: absolute;
	top: 38%;
	left: -2rem;
	transform: translateY(-50%);
}

.lory__slider .next{
	position: absolute;
	top: 38%;
	right: -2rem;
	transform: translateY(-50%);
}

.lory__slider .prev{
	left: 0px;
}

.lory__slider .next{
	right: 0px;
}

.lory__slider .prev, .lory__slider .next{
	width: 2.625rem;
	height: 2.625rem;
	background: rgba(255,255,255, 0.75);
}

.slider__arrow{
	height: 1.625rem;
	width: auto;
	position: absolute;
	top: 50%;
	left: 50%;
}

.lory__slider .prev .slider__arrow{
	transform: translate(-75%, -50%);
}

.lory__slider .next .slider__arrow{
	transform: translate(-25%, -50%);
}

.prev.disabled,
.next.disabled{
	display: none !important;
}

@media (max-width: $breakpoint-medium){
	.lory__wrapper{
		width: 100vw;
	}
}