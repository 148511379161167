@import 'variables/typography';

body{
    font-family: $sans-base-font-family;
    font-weight: 400;
}

a{
    color: $standard-link-color;
}

p a, blockquote a{
    border-bottom: 1px solid #000;
}

b{
    font-weight: $demi;
}

p b{
    font-weight: $demi;
}

.bigbold{
    font-size: 1.25em;
    font-weight: $demi;
}

@mixin article-body-typography{
    font-size: $article-base-font-size;
    line-height: $article-base-line-height;
}

@mixin article-header-typography{
    font-weight: $demi;
    text-transform: uppercase;
    font-size: $column-title-font-size-desktop;
    margin: 0px;
    line-height: 1.5em;
    @media screen and (max-width: $breakpoint-medium){
        font-size: $column-title-font-size-mobile;
    }
}

@mixin channel-typography{
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: $book;
    line-height: 1em;
    font-family: $sans-base-font-family;
}

@mixin caption-typography{
    font-size: .875rem;
    line-height: 1.25em;
    font-family: $sans-base-font-family;
}

@mixin article-meta{
    margin: .5rem 0 3rem 0;
    font-size: 1.25rem;
    line-height: 1.25em;
}

.slug{
    @include channel-typography;
    margin: $slug-margin;
    line-height: 1em;
    display: block;
}

.slug--mobile {
    display: none;
}

.slug--margin-top{
    @include channel-typography;
    margin: 3rem 0 1rem 0;
    display: block;
}

.slug--no-margin{
    @include channel-typography;
    display: block;
}

.slug--single-article{
    @include channel-typography;
    display: block;
    margin-bottom: 1em;
}

.section-heading{
    @include channel-typography;
    // margin: $slug-margin;
}

@media screen and (max-width: $breakpoint-medium){
    .slug{
        margin: $slug-margin-mobile;
    }

    .slug--toc {
        display: none;
    }

    .slug--mobile {
        display: block;
    }

    .slug--margin-top{
        margin: 1.5rem 0 1rem 0;
    }
}

