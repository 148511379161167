.print-archive__navigation{
	flex-basis: 320px;
	flex-grow: 0;
	flex-shrink: 0;
}

.print-archive__decade-heading{
	font-size: 1.5rem;
	margin: .5rem 0 0;
}

.print-archive__volume-link{
	margin-left: .5rem;
	text-transform: uppercase;
	margin-bottom: .75rem;
}

.print-archive__decade-link--current{
	color: $issue-color;
}

.print-archive__list-by-decade{
	display: none;
}

.print-archive__list-by-decade--current{
	display: block !important;
}

.print-archive__content{
	flex-grow: 1;
}

.print-archive__volume-heading{
	font-size: 1.5rem;
}

.print-archive__issue{
	position: relative;
	line-height: 1.5rem;
}

.print-archive__image{
	width: 100%;
}

.archive__next-link{
	float: left;
}

.archive__previous-link{
	float: right;
}

.print-archive__issue-title{
	line-height: 1.5em;
	margin: 1em 0;
}

.print-archive__purchase-link, .print-archive__toc-link{
	display: block;
	margin-bottom: .5rem;
}

.print-archive__issue:hover .print-archive__info{
	display: block;
}

.print-archive__issue-title{
	text-transform: uppercase;
}

.print-archive__subscribe-message{
	margin-top: 0;
	font-size: 1.125rem;
}

.print-archive__volume-title{
	font-size: 1.5rem;
	margin: 1em 0px;
}

@media screen and (max-width: $breakpoint-large) and (min-width: $breakpoint-medium){
	.print-archive__purchase-link{
		float: none;
	}
}

@media screen and (max-width: $breakpoint-medium){

	.print-archive{
		margin-top: 1.5rem;
	}

	.print-archive__issue{
		margin-bottom: 4rem !important;
	}
	
	.print-archive__issue-title{
		margin: 0 0 1em 0;
		font-size: 1.125rem;
		div{
			display: inline;
		}
		div:first-child::after{
			content: ', '
		}
	}
	
	.print-archive__toc-link, .print-archive__purchase-link{
		padding: .5em;
		border: 1px solid black;
		text-align: center;
	}

	.print-archive__slider{
		margin-bottom: 8rem
	}

	.print-archive__frame{
		width: 90vw !important;
		overflow: hidden;
		margin: 0 auto;
	}

	.print-archive__slide{
		width: 90vw;
	}

	.print-arvhice__slide img{
		max-width: 100%;
	}

	.print-archive__volume-issue{
		display: inline;
	}

}

@media screen and (min-width: $breakpoint-medium){
	
	.print-archive__navigation{
		width: 320px; // fix for non-flex supporting browsers
	}

	.print-archive{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	.print-archive::after{
		content: "";
		display: block;
		flex-grow: 1;
	}

	.print-archive__issue{
		width: 17%;
		margin-bottom: 1.5rem;
		display: inline-block;
		margin-right: 3%;
		vertical-align: top;
		flex-basis: 163px;
		flex-shrink: 0;
	}

	.print-archive__info{
		position: absolute;
		top: 0px;
		bottom:0px;
		left: 0px;
		right: 0px;
		padding: 1rem;
		background: rgba(0, 0, 0, .75);
		display: none;
		text-align: center;
		text-transform: uppercase;
		font-size: .875rem;
		color: #fff;
		a {
			color: #fff;
			&:hover{
				color: $issue-color;
			}
		}
	}

	.print-archive__link{
		display: block;
	}

	.print-archive__info{
		box-sizing: border-box;
	}

	.print-archive__link, .print-archive__info{
		max-width: 163px;
	}

	.print-archive__image{
		box-shadow: 5px 5px 10px #ccc;
	}

	// use grid layout where available
	.print-archive{
		display: grid;
		grid-gap: 2rem;
		grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
		grid-auto-rows: minmax(163px, 1fr);
	}
}

// for flexbox version, overridden below for grid layout
@media screen and (min-width: 1157px) and (max-width: 1328px){
	.print-archive__issue:nth-child(9){
		margin-right: 6.4%;
	}
}

// override breaking styles from flexbox

@supports (display: grid){
	.print-archive__issue{
		width: auto;
		margin: 0;
	}

	.print-archive::after{
		display: none;
	}

	.print-archive__link, .print-archive__info{
		max-width: none;
	}

	.print-archive__issue:nth-child(9){
		margin-right: 0;
	}
}
