
@import "scss/variables/typography";

.related-articles__slides--mobile {
    margin: 1rem 0 1rem 0;
}

.related-articles__slide--mobile {
    text-align: center;
    width: 100vw;
}

.related-articles__slider--mobile .prev, .related-articles__slider--mobile .next{
    display: none;
}

.related-articles__js_dots--mobile{
    text-align: center;
}

.related-articles__js_dots--mobile li{
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #aaa;
    margin-right: 15px;
    border-radius: 8px;
}

.related-articles__js_dots--mobile li.active{
    background: #000;
}
