$text-grayscale-dark: #313131;
$text-grayscale-light: #808080;
$standard-link-color: #000;
$nonstandard-link-color: #004DFF;
$text-grayscale-extra-light: #ccc;

$red: red;

$serif-base-font-family: 'Helvetica','source-han-sans-simplified-c', 'STXihei', 'Microsoft YaHei', 'Heiti', sans-serif;
$sans-base-font-family: 'Helvetica','source-han-sans-simplified-c', 'source-han-sans-simplified-c', 'STXihei', 'Microsoft YaHei', 'Heiti', sans-serif; ;
$topten-number-font-family: "anzeigen-grotesk", "Arial Black", sans-serif;

$book: 400;
$medium: 700;
$demi: 700;
$heavy: 900; 

$article-base-font-size: 1.125rem;
$article-base-line-height: 1.5555555555em;

$pull-quote-font-size: 26px;
$pull-quote-line-height: 32px;

$column-title-font-size-desktop: 3rem;
$column-title-font-size-mobile: 2.25rem;

