
@import "scss/variables/typography";
@import "scss/variables/breakpoints";
@import "scss/variables/grid";

.more-images{
	display: block;
	font-family: $sans-base-font-family;
	font-size: .875rem;
	font-weight: $demi;
	line-height: 1.375rem;
	text-transform: uppercase;
}

.more-images--text{
	margin-top: .25rem;
}

.enlarge-icon{
	width: 1rem;
	height: 1rem;
}

@media screen and (min-width: $breakpoint-medium){
	.more-images--text{
		margin-left: 0px;
		margin-right: 0px;
	}
}
