
@import "scss/variables/typography";
@import "scss/variables/grid";
@import "scss/variables/breakpoints";

.related-articles{
	margin-bottom: 2rem;
}

.related-articles__border{
	background: $text-grayscale-dark;
	margin: 2rem 0px 2rem 0px;
}

.related-articles__heading{
	padding: 0;
	font-family: $sans-base-font-family;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 1.375rem;
}


@media (min-width: $breakpoint-medium){

	.related-articles__heading{
		text-align: center;
	}

	.related-articles__border{
		margin: 2rem 0 2rem 0;
	}

	.related-articles__heading{
		text-align: left;
	}
}
