
@import "scss/variables/typography";

.hp-news__date{
	font-size: 1rem;
	font-weight: $book;
	margin: .5rem 0px;
}
.hp-news__title{
	margin: 0px;
	text-transform: uppercase;
	font-size: 1.125rem;
	font-weight: $medium;
}
