
@import "scss/variables/breakpoints";
@import "scss/variables/grid";
@import "scss/variables/typography";

.related-article{
    font-size: 1.0625rem;
    display: block;
    color: #000;
}

.related-article__title{
    font-weight: $demi;
}

.related-article__image{
    max-width: 100% !important;
}

.related-article__section{
    padding: 0;
    margin: 1rem 0px 0px 0px;
    text-align: left;
    font-family: $sans-base-font-family;
    text-transform: uppercase;
    font-weight: $book;
    line-height: 1.125em;
    margin-bottom: .25rem;

    span{
        text-transform: none;
    }
}

.related-article__info{
    text-align: left;
    font-family: $sans-base-font-family;
    line-height: 1.25em;
}

.related-article__section,
.related-article__info{
    margin-left: $mobile-margins-left;
}

@media screen and (min-width: $breakpoint-medium){
    .related-article__section,
    .related-article__info{
        margin-left: 0;
    }
}
