.footer{
    background: $issue-color;
    margin-top: 5rem;
    padding: 36px 1rem;
    font-weight: 100;
    position: relative;

    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.footer__signup{
    color: #fff;
}

.footer__signup-header{
    font-size: 1rem;
    margin: .5rem 0 0 0;
    font-weight: $demi;
}

.footer__signup-note{
    margin: .5rem 0;
    font-size: .875rem;
}

.footer__signup-form{
    width: 100%;
}

.footer__signup-input{
    color: #000;
    //width: 85%;
    box-sizing: border-box;
    border-radius: 0px;
    padding: .5rem;
    border: none;
    flex-grow: 1;
}

.footer__signup-submit{
    //width: 15%;
    box-sizing: border-box;
    background: #fff;
    color: #000;
    padding: .325rem;
    border-left: 1px solid $text-grayscale-extra-light;
    font-size: .875rem;
}

.footer__links, .footer__links a{
    color: #fff;
}

.footer__site-links{
    line-height: 1.5em;
    column-count: 2;
}

.footer__copyright{
    font-size: .875rem;
}

.footer__copyright a{
    border-bottom: 1px solid #fff;
}

.footer__social-icon{
    height: 1rem;
    width: auto;
    margin-left: .5rem;
}

@media screen and (min-width: $breakpoint-medium){
    .footer__signup{
        width: 38%;
        padding-right: 2%;
        border-right: 2px solid #fff;
    }

    .footer__links{
        width: 58%;
        padding-left: 2%;
    }

    .footer__site-links{
        column-count: 3;
    }
}
