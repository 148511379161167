
@import "scss/variables/issue";
@import "scss/variables/typography";
@import "scss/variables/grid";
@import "scss/mixins";
	.sticky-navigation__internal-link{
		text-transform: uppercase;
		display: block;
		margin-bottom: .25em;
		color: #000;
		font-weight: $book;
	}
	
	.sticky-navigation__internal-link--active{
		color: $issue-color;
	}

	.sticky-navigation__internal-link--small{
		font-size: 1.125rem;
	}
	
	.sticky-navigation__internal-link--current{
		color: $issue-color;
	}
	@media (max-width: $breakpoint-medium){
		.sticky-navigation{
			display: flex;
			flex-direction: column-reverse;
		}
		.left-navigation__navigation > .sticky-navigation{
			margin-top: $slug-margin;
		}
		.sticky-navigation__internal-links{
			display: flex;
			margin-bottom: 2rem;
			justify-content: space-between;
		}
		.sticky-navigation--no-flex .sticky-navigation__internal-links{
			display: block;
		}
	}
	@media (min-width: $breakpoint-medium){
		.sticky-navigation{
			position: -webkit-sticky;
			position: sticky;
			top: $sticky-position-top;
		}
		.sticky-navigation__internal-links{
			margin-bottom: 2rem;
			margin-top: 1.375rem;
			font-size: 1.5rem;
		}
	}
